import axios from '@/plugins/http/axios';
import {
  RegisterData,
  RegisterPayload,
  WorkHoursRecord,
} from '@/shared/types/registerWorking';

export default {
  getRegister(): Promise<RegisterData> {
    return axios.get('/working-hours');
  },

  createRegister(register: RegisterPayload): Promise<void> {
    return axios.post('/working-hours', register);
  },

  getAllRecords(): Promise<WorkHoursRecord> {
    return axios.get('working-hours/registers');
  },
};
