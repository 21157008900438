import {
  RegisterData,
  RegisterPayload,
  RegisterRequest,
  RegisterWork,
  WorkHours,
  WorkHoursRecord,
} from '@/shared/types/registerWorking';
import { defineStore } from 'pinia';
import registerWorkService from '@/shared/services/registerWork/registerWorkService';
import { reactive, ref } from 'vue';

const useRegisterWorkStore = defineStore('registerWork', () => {
  const listRegister: RegisterData = reactive({
    register: {
      Turno: {} as RegisterWork,
      Pausa: {} as RegisterWork,
    },
  });

  const registerPost: RegisterRequest = reactive({
    register: '',
    motiveRegister: '',
  });

  const allWorkHoursRecords = reactive({}) as WorkHoursRecord;

  const isLoading = ref(false);
  const isFailure = ref(false);

  async function loadAllRecords(): Promise<void> {
    try {
      const records = await registerWorkService.getAllRecords();
      allWorkHoursRecords.userTimezone = records.userTimezone;
      allWorkHoursRecords.userWorkingHours = records.userWorkingHours;
    } catch (e) {
      allWorkHoursRecords.userTimezone = '';
      allWorkHoursRecords.userWorkingHours = [] as WorkHours[];
    }
  }

  const loadRegister = async () => {
    try {
      isLoading.value = true;
      listRegister.register = (
        await registerWorkService.getRegister()
      ).register;
      await loadAllRecords();
      isLoading.value = false;
    } catch (e) {
      isLoading.value = false;
      listRegister.register = {
        Turno: {} as RegisterWork,
        Pausa: {} as RegisterWork,
      };
    }
  };

  async function createRegisterWorkHour(
    register: RegisterPayload,
  ): Promise<void> {
    return registerWorkService.createRegister(register);
  }

  return {
    registerPost,
    createRegisterWorkHour,
    loadRegister,
    isLoading,
    isFailure,
    listRegister,
    loadAllRecords,
    allWorkHoursRecords,
  };
});

export default useRegisterWorkStore;
